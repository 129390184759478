
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <h1 class="mt-4 mb-5">{{$store.getters.language.data.salary_report.title}}</h1>
            <form @submit.prevent="updateSalaryReport" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <!-- <v-text-field v-model="salary_report.salary_report_status" type="text" :label="$store.getters.language.data.salary_report.salary_report_status" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field> -->
                        <!-- v-select for the salaray_reort_statys show or hide  -->
                        <v-select class="mx-1" clearable  :items="salary_report_status" v-model="salary_report.salary_report_status" dense  filled outlined  item-text="text"
                                    item-value="salary_report_status" :return-object="false" :label="$store.getters.language.data.salary_report.salary_report_status">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg4 xl4 md4 sm4>
                        <v-select class="mx-1" clearable  :items="salary_list" v-model="salary_report.salary_list_id" dense  filled outlined  item-text="salary_format"
                                    item-value="salary_list_id" :return-object="false" :label="$store.getters.language.data.salary_list.salary_list_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="primary"    type="submit">{{$store.getters.language.data.salary_report.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
import salary_listRequest from '../../requests/salary_list.request.js'
    import requests from './../../requests/salary_report.request.js'
    
    export default {
        data() {
            return {
                salary_report: {},
                salary_list: [],
                // salary_report_status valuse 0 and 1 and the text be show or hide 
                salary_report_status: [
                    { salary_report_status: 0, text: 'Hide' },
                    { salary_report_status: 1, text: 'Show' }
                ],
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            
                    // salary_list(){
                    //     return this.$store.getters.salary_list_list
                    // },
                    
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneSalaryReport()
            this.getSalaryList()
        },
        methods: {
            getSalaryList() {
               this.loading = true
                salary_listRequest.getAllSalaryList().then(r => {
                     if (r.status == 200) {
                         this.salary_list = r.data.rows
                          this.loading = false
                     } else {
                          this.snackbar = {
                            value: true,
                            text: 'Fail to read SalaryList',
                            color: 'error'
                          }
                     }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read SalaryList',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },


            getOneSalaryReport() {
                this.loading = true
                requests.getOneSalaryReport(this.id).then(r =>{
                    if (r.status == 200) {
                        this.salary_report = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read SalaryReport',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read SalaryReport',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateSalaryReport() {
                this.loading = true
                requests.updateSalaryReport(this.id,this.salary_report).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'SalaryReport Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    